// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'sitdev',
  production: false,
  api_base_path: 'https://vet.sitdev.hillstohome.com',
  img_base_path: 'https://vet.sitdev.hillstohome.com',
  client_id: 'mobile',
  client_secret: 'secret',
  grant_type: 'password',
  stripe_publishable_key: '{(UAT_STRIPE_PUBLISHABLE_KEY)}',
  google_captcha_site_key:'6LdW630UAAAAANO9_BCzYVdAU6uhVuqFyb5djf7u',
  google_invisible_captcha_site_key: '6LeKMqQkAAAAAEApLiR_ko03XgMHFDT_y8Q2_POM'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.